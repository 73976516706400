import React, { useEffect, useState } from "react";
import { AppLoadingButton, AppText, AppTitle, FlexDiv } from "../components/ui";
import { BasicLayout } from "../layouts/BasicLayout";
import DigitalWalletIcon from "../components/ui/DigitalWalletIcon";
import theme from "../util/theme";
import styled from "styled-components";

import { baseUrl, BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, mobileSize } from "../util/variables";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import CheckIcon from "../content/img/icon/CheckMark.svg";
import BellIcon from "../content/img/icon/bell.svg";
import { useAuth } from "../hooks/useAuth";
import { web_acq_prepayment_attempt, web_acq_prepayment_failure, web_acq_prepayment_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { GTM_CUSTOM_EVENT } from "../util/types";
import { triggerCustomEvent } from "../services/google-tag-manager";
import { Subscribe_Account_AccountCreationSuccess, setMixpanelProperties, trackEvent } from "../services/mixpanel/mixpanel";
import { web_acq_prepayment_success } from "../services/mixpanel/acquisition-events";
import { saveUserOnboardingData } from "../services/firebase";
import { CHECKOUT_URL_KEY_PREFIX } from "../pages/try";
import { UNIVERSAL_PRICE_IDS } from "../util/local-price-ids";
import { getStripeUrlPaidTrial } from "../actions/stripeAction";
import { PAID_TRIAL_PRICE_IDS } from "../util/local-price-ids";
import { navigate } from "gatsby";

const priceId = "price_1Pqn4BLvyjRKGUTXFzFNdPM4";

export default ({ pageContext, location }) => {
  const [stripeUrl, setStripeUrl] = useState<string>("");
  const [trackingComplete, setTrackingComplete] = useState(false);
  const [redirectClicked, setRedirectClicked] = useState(false);
  const [topics, setTopics] = useState<string[]>(["Philosophy", "Psychology", "Health & Science"]);
  const { price } = pageContext;
  const { onboardingAnswers } = useOnboardingContext();
  const { user, loading: loadingUser } = useAuth();
  const { state } = location;

  // get onboarding topics for the custom plan
  useEffect(() => {
    const onboardingTopics = onboardingAnswers["whichTopicsDoYouWant_3"];
    if (!onboardingTopics) return;
    const top3Topics = onboardingTopics.split(", ").slice(0, 3);
    setTopics(top3Topics);
  }, [onboardingAnswers]);

  const redirectToStripe = (url: string) => {
    fbq("track", "InitiateCheckout");
    triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_SUCCESS);
    trackEvent("PaidTrial_CheckoutInitiated", { price });
    web_acq_prepayment_success();
    setTimeout(() => {
      document.location.href = url;
    }, 100);
  };

  const handleRedirect = () => {
    web_acq_prepayment_attempt();
    setRedirectClicked(true);
  };

  // Post Account creation set up 3rd party tracking and analytics
  useEffect(() => {
    const trackNewUser = async (uid: string, email: string, onboardingAnswers: object) => {
      const universalControlGroup = Math.floor(Math.random() * 10) + 1;
      const storedUtm = localStorage.getItem("utmData");

      web_acq_prepayment_view();
      trackEvent("PaidTrial_PrepaymentPageView");
      triggerCustomEvent(GTM_CUSTOM_EVENT.WEB_ACQ_PREPAYMENT_VIEW);

      const promises = [
        import("../services/braze").then(({ initialize, changeUser, getUser, openSession }) => {
          initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
          openSession();
          changeUser(uid);
          const user = getUser();
          if (user) {
            user.setEmail(email);
            user.setCustomUserAttribute("PrepaymentABPrice", priceId ?? "no price found");
            user.setCustomUserAttribute("customer_pool_id", universalControlGroup);
            console.log("Braze user and email has been set");
          }
        }),
        setMixpanelProperties({ AccountCreatedPlatform: "Web" }, true),
        Subscribe_Account_AccountCreationSuccess(email, uid),
        saveUserOnboardingData({
          uid,
          email,
          onboardingAnswers,
          userWebData: storedUtm ? JSON.parse(storedUtm) : {},
          universalControlGroup,
        }),
        fbq("track", "CompleteRegistration"),
      ];

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("Error during user tracking:", error);
      } finally {
        setTrackingComplete(true);
      }
    };

    if (state?.uid && state?.email) {
      console.log("email", state.email);
      trackNewUser(state.uid, state.email, onboardingAnswers);
    } else {
      setTrackingComplete(true);
    }
  }, [onboardingAnswers, state]);

  useEffect(() => {
    const getStripeURL = async () => {
      const savedCheckoutUrl = sessionStorage.getItem(`${CHECKOUT_URL_KEY_PREFIX}_${price}`);
      if (savedCheckoutUrl) {
        setStripeUrl(savedCheckoutUrl);
        return;
      }

      try {
        const email = state?.email ?? user?.email;
        const uid = state?.uid ?? user?.uid;

        if (!email) {
          throw new Error("We require email in this flow to create a checkout session");
        }

        const stripeURL = await getStripeUrlPaidTrial({
          email,
          uid,
          cancel_url: `${baseUrl}/try/${price}`,
          success_url: `${baseUrl}/complete?session_id={CHECKOUT_SESSION_ID}&uid=${uid}&trial_price=${price}`,
          mode: "subscription",
          priceIds: [
            PAID_TRIAL_PRICE_IDS[price as keyof typeof PAID_TRIAL_PRICE_IDS],
            UNIVERSAL_PRICE_IDS[99.99],
          ],
          currency: "USD",
          subscription_data: { trial_period_days: 7 },
        });

        setStripeUrl(stripeURL);
        sessionStorage.setItem(`${CHECKOUT_URL_KEY_PREFIX}_${price}`, stripeURL);
      } catch (err) {
        console.error(err);
        web_acq_prepayment_failure();
        // eslint-disable-next-line no-alert
        alert(
          "Something went wrong getting your checkout link. Please try again going through onboarding again.",
        );

        navigate("/get-started");
      }
    };

    if (!loadingUser) {
      getStripeURL();
    }
  }, [loadingUser, state, user, price]);

  useEffect(() => {
    if (redirectClicked && price && Boolean(stripeUrl) && trackingComplete) {
      redirectToStripe(stripeUrl);
    }
  }, [redirectClicked, stripeUrl, price, trackingComplete]);

  return (
    <BasicLayout noIndex>
      <FlexDiv align="center" style={{ background: "#FAFAFA", width: "100vw" }}>
        <Main>
          <AppTitle textAlign="center" style={{ marginBottom: 12 }}>Invest in Yourself</AppTitle>
          <AppText textAlign="center">
            Learn more in less time—see how it works.
          </AppText>
          <YourCustomPlan>
            YOUR CUSTOM PLAN
          </YourCustomPlan>
          <PlanContainer>
            <Checklist>
              <li>
                <img src={CheckIcon} alt="Checkmark" height={14.5} width={20} />
                <AppText noMargin fontSize={16} mobileFontSize={14}>
                  Unlimited access to lessons in <span style={{ fontWeight: 700 }}>{topics.join(", ")}</span>{topics.length > 1 && ","} and more
                </AppText>
              </li>
              <li>
                <img src={CheckIcon} alt="Checkmark" height={14.5} width={20} />
                <AppText noMargin fontSize={16} mobileFontSize={14}>
                  Build a learning habit
                </AppText>
              </li>
              <li>
                <img src={CheckIcon} alt="Checkmark" height={14.5} width={20} />
                <AppText noMargin fontSize={16} mobileFontSize={14}>
                  Master complex topics in over 25 categories
                </AppText>
              </li>
              <li>
                <img src={CheckIcon} alt="Checkmark" height={14.5} width={20} />
                <AppText noMargin fontSize={16} mobileFontSize={14}>
                  Replace doomscrolling with bite-sized learning
                </AppText>
              </li>
            </Checklist>
          </PlanContainer>

          <PriceHeader>
            <PriceTitle>
              PRICE TODAY
            </PriceTitle>
            <PriceText fontWeight={700}>
              ${price.toFixed(2)}
            </PriceText>
          </PriceHeader>
          <AppText style={{ marginTop: 8, marginBottom: 0 }}>
            7-day trial with unlimited access.
          </AppText>
          <Alert>
            <Bell />
            <AppText noMargin>
              We’ll remind you 2 days before your trial ends
            </AppText>
          </Alert>

          <PriceHeader>
            <PriceTitle>
              PRICE AFTER TRIAL
            </PriceTitle>
            <PriceText>
              $99.99/year
            </PriceText>
          </PriceHeader>
          <AppText style={{ marginTop: 8, marginBottom: 0 }}>
            Annual subscription, billed after 7-day trial. Cancel anytime.
          </AppText>

          <AppLoadingButton
            type="button"
            loading={redirectClicked}
            disabled={redirectClicked}
            style={{ marginTop: 52, marginLeft: "auto", marginRight: "auto" }}
            onClick={handleRedirect}
          >
            Start Your 7-Day Trial
          </AppLoadingButton>
          <button
            type="button"
            disabled={redirectClicked}
            style={{ display: "block", background: "none", border: "none", margin: "0 auto" }}
            data-cta-type="wallet_pay"
            onClick={handleRedirect}
          >
            <AppText color={theme.PRIMARY_COLOR} fontWeight={500} textAlign="center" style={{ marginTop: 16, marginBottom: 8 }}>
              In a rush? Activate your trial with <DigitalWalletIcon />
            </AppText>
          </button>
        </Main>
      </FlexDiv>
    </BasicLayout >
  );
};

const YourCustomPlan = styled.h2`
  margin-top: 64px;
  font-size: 18px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};

  @media ${mobileSize} {
    margin-top: 40px;
  }
`;

const Main = styled.main`
  max-width: 708px;
  margin: 0 auto;
  padding: 84px 40px;
  background-color: ${theme.WHITE_COLOR_2};

  @media ${mobileSize} {
    padding: 32px 24px 80px 24px;
  }
`;

const PriceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #EDEEEF;
  margin-top: 32px;
  padding-bottom: 8px;
`;

const PriceTitle = styled(AppText).attrs({
  fontSize: 18,
  fontWeight: 500,
  noMargin: true,
})`
  text-transform: uppercase;
`;

const PriceText = styled(AppText).attrs({
  fontSize: 18,
  mobileFontSize: 16,
  noMargin: true,
})`
  color: ${theme.PRIMARY_COLOR};
`;

const PlanContainer = styled.div`
  background-color: #CDDC9980;
  padding: 24px 32px;
  border-radius: 24px;
  margin-top: 24px;
  margin-bottom: 44px;
  @media ${mobileSize} {
    padding: 16px 24px;
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

const Checklist = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0px;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  
  @media ${mobileSize} {
    li {
      gap: 20px;
    }
    
    li:first-child {
      align-items: flex-start;
      img {
        margin-top: 10px;
      }
    }
  }
`;

const Alert = styled(FlexDiv).attrs({
  gap: 8,
})`
  align-items: center;
  max-width: max-content;
  background-color: #FF61451A;
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;

  @media ${mobileSize} {
    align-items: flex-start;
  }
`;

const Bell = styled.img.attrs({
  src: BellIcon,
  alt: "Bell",
  width: 21,
  height: 21,
})`
  @media ${mobileSize} {
    margin-top: 4px;
  }
`;
