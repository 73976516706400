import React, { useEffect, useState } from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import {
  trackEvent,
} from "../../services/mixpanel/mixpanel";
import { laptopSize, mobileSize } from "../../util/variables";
import { AppText, AppTitle, FlexDiv } from "../../components/ui";
import theme from "../../util/theme";
import styled from "styled-components";
import UpArrow from "../../content/img/icon/arrow-up-orange.svg";
import PrepaymentHeroImage from "../../components/PageSegments/special-offer/prepayment/PrepaymentHeroImage";
import { AppLink } from "../../components/ui/AppLink";

export const CHECKOUT_URL_KEY_PREFIX = "stripeCheckoutURL_paid_trial";

export type PaidTrialPrice = 1 | 7 | 11.41;

type PriceCard = {
  price: PaidTrialPrice;
  title: string;
};

const PRICE_CARDS: PriceCard[] = [
  {
    price: 1,
    title: "$1",
  },
  {
    price: 7,
    title: "$7",
  },
  {
    price: 11.41,
    title: "$11.41*",
  },
];

export default (data: any) => {
  const [selectedPrice, setSelectedPrice] = useState(11.41);
  const { state } = data.location;

  const handlePriceChange = (price: number) => {
    trackEvent("PaidTrial_PriceChange", { price });
    setSelectedPrice(price);
  };

  useEffect(() => {
    trackEvent("PaidTrial_PageView");
  }, []);

  return (
    <BasicLayout noIndex>
      <main style={{ backgroundColor: theme.WHITE_COLOR_2, minHeight: "100vh" }}>
        <Container>
          <AppTitle textAlign="center" style={{ maxWidth: "14ch" }}>
            Try for a week, pay what you want.
          </AppTitle>
          <AppText textAlign="center" style={{ maxWidth: "64ch" }}>
            We’re building a better way to learn. Our trial plans make Imprint accessible to everyone, while supporting the educators and artists who make it all possible.{" "}
            <DesktopBreak />
            <b style={{ color: theme.RED }}>Choose a trial plan you’re comfortable with.</b>
          </AppText>
          <CardsContainer>
            <FlexDiv gap={16}>
              {PRICE_CARDS.map(card => (
                <PriceCard key={card.title} selected={selectedPrice === card.price} onClick={() => handlePriceChange(card.price)}>
                  {card.title}
                </PriceCard>
              ))}
            </FlexDiv>

            <AppText fontSize={14} mobileFontSize={13} color={theme.SLATE_COLOR} style={{ display: "flex", marginTop: 20 }}>
              *This plan helps us support those who select the lowest price option
              <img src={UpArrow} height={26} width={40} style={{ rotate: "-20deg" }} />
            </AppText>
          </CardsContainer>
          <AppLink
            type="button"
            disabled={!selectedPrice}
            data-cta-type="button"
            to={`/try/${selectedPrice}`}
            state={{
              email: state?.email,
              uid: state?.uid,
            }}
            onClick={() => {
              trackEvent("PaidTrial_ContinueToPrepayment", { price: selectedPrice });
            }}
          >
            Continue
          </AppLink>
        </Container>
        <PrepaymentHeroImage />
        <DisclaimerText>
          Cancel anytime—we&apos;ll remind you before your trial ends. After your 7-day trial ends, you will be charged an annual subscription of $99.99 per year.
        </DisclaimerText>
      </main>
    </BasicLayout>
  );
};

const Container = styled(FlexDiv).attrs({
  direction: "column",
  align: "center",
})`
  padding: 24px;
  padding-top: 84px;
  padding-bottom: 84px;
  @media ${mobileSize} {
    padding-top: 32px;
    padding-bottom: 40px;
  }
`;

const PriceCard = styled.button.attrs({
  type: "button",
}) <{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 143px;
  height: 96px;
  border-radius: 8px;
  background-color: ${props => props.selected ? theme.SELECTED_COLOR : theme.WHITE_COLOR};
  box-shadow: 0px 2px 7px 0px #2C273824;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;

  @media ${mobileSize} {
    width: 100%;
    max-width: 143px;
    height: 64px;
    font-size: 17px;
  }
`;

const DesktopBreak = styled.br`
  @media ${mobileSize} {
    display: none;
  }
`;

const CardsContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 32px;

  @media ${mobileSize} {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`;

const DisclaimerText = styled(AppText).attrs({
  color: theme.SLATE_COLOR,
  fontSize: 16,
  mobileFontSize: 13,
  textAlign: "center",
})`
  max-width: 900px;
  padding: 80px 24px;
  margin: 0 auto;

  @media ${laptopSize} {
    max-width: 780px;
  }

  @media ${mobileSize} {
    margin-top: 24px;
    padding-bottom: 86px;
  }
`;
